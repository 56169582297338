import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import "./CadastroXLSX.css";

const CadastroXLSX = () => {
  const [xlsxData, setXlsxData] = useState(null);
  const [linkForm, setLinkForm] = useState({ url: "" });
  const [message, setMessage] = useState("");
  const [cadastradosItems, setCadastradosItems] = useState([]);
  const [cadastradoLink, setCadastradoLink] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchLink();
  }, []);

  const fetchLink = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://api.transportesseguros.com.br/api/links");
      console.log("Resposta da API:", response.data);
      if (response.data && response.data.length > 0) {
        setCadastradoLink(response.data[0].url);
      } else {
        console.log("Nenhum link encontrado na resposta da API");
        setCadastradoLink("");
      }
    } catch (error) {
      console.error("Erro ao buscar link:", error);
      setMessage(
        "Erro ao carregar o link cadastrado. Por favor, tente novamente mais tarde."
      );
      setCadastradoLink("");
    } finally {
      setIsLoading(false);
    }
  };

  const parseDate = (dateString) => {
    if (!dateString) return null;

    const [date, time] = dateString.split(" ");
    const [day, month, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}T${time}:00`;
  };

  const mapXlsxToItemDTO = (xlsxItem) => {
    return {
      nome: xlsxItem["Cliente"] || "",
      cpf: xlsxItem["CPF/CNPJ"] || "",
      valor: xlsxItem["Valor"]
        ? parseFloat(xlsxItem["Valor"].replace(",", "."))
        : 0,
      endereco: {
        rua: xlsxItem["Endereço"] || "",
        numero: xlsxItem["Número"] || "",
        cidade: xlsxItem["Cidade"] || "",
        bairro: xlsxItem["Bairro"] || "",
        estado: xlsxItem["Estado"] || "",
        cep: xlsxItem["CEP"] || "",
        complemento: xlsxItem["Complemento"] || "",
      },
      produtos: xlsxItem["Produtos"]
        ? xlsxItem["Produtos"].split(";").map((prod) => {
            const match = prod.match(/(.+) $$x(\d+)$$/);
            return match
              ? { nome: match[1].trim(), quantidade: parseInt(match[2]) }
              : { nome: prod.trim(), quantidade: 1 };
          })
        : [],
      dataPagamento: parseDate(xlsxItem["Data de pagamento"]) || null,
      email: xlsxItem["Email"] || "",
      telefone: xlsxItem["Telefone"] || "",
      formaPagamento: xlsxItem["Forma de pagamento"] || "",
      status: xlsxItem["Status"] || "",
      statusEntrega: xlsxItem["Status da entrega"] || "",
    };
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      const mappedData = data
        .map(mapXlsxToItemDTO)
        .filter((item) => item.nome && item.cpf);
      setXlsxData(mappedData);
      setMessage(`Arquivo carregado com ${mappedData.length} itens válidos.`);
    };
    reader.readAsBinaryString(file);
  };

  const handleCadastroXLSX = async () => {
    if (!xlsxData || xlsxData.length === 0) {
      setMessage("Por favor, carregue um arquivo XLSX válido primeiro.");
      return;
    }

    try {
      const novosCadastrados = [];
      for (const item of xlsxData) {
        console.log("Enviando item:", JSON.stringify(item));
        const response = await axios.post(
          "http://localhost:5149/api/items",
          item
        );
        console.log("Resposta do servidor:", response.data);
        novosCadastrados.push({ nome: item.nome, cpf: item.cpf });
      }
      setCadastradosItems((prev) => [...prev, ...novosCadastrados]);
      setMessage(`${xlsxData.length} itens cadastrados com sucesso!`);
      setXlsxData(null);
    } catch (error) {
      setMessage(
        "Erro ao cadastrar itens: " +
          (error.response?.data?.errors?.$?.[0] || error.message)
      );
      console.error("Erro completo:", error.response?.data);
    }
  };

  const handleLinkChange = (e) => {
    setLinkForm({ ...linkForm, [e.target.name]: e.target.value });
  };

  const handleLinkSubmit = async (e) => {
    e.preventDefault();
    try {
      if (cadastradoLink) {
        await axios.put("https://api.transportesseguros.com.br/api/links", linkForm);
      } else {
        await axios.post("https://api.transportesseguros.com.br/api/links", linkForm);
      }
      setCadastradoLink(linkForm.url);
      setMessage("Link atualizado com sucesso!");
      setLinkForm({ url: "" });
      fetchLink();
    } catch (error) {
      console.error("Erro ao atualizar link:", error);
      setMessage("Erro ao atualizar link. Por favor, tente novamente.");
    }
  };

  return (
    <div className="cadastro-container">
      <h2>Cadastro de Itens via XLSX e Links</h2>

      <div className="cadastro-form">
        <h3>Upload de Arquivo XLSX</h3>
        <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
        <button
          onClick={handleCadastroXLSX}
          disabled={!xlsxData || xlsxData.length === 0}
        >
          Cadastrar Itens do XLSX
        </button>
      </div>

      <form onSubmit={handleLinkSubmit} className="cadastro-form">
        <h3>Cadastro/Atualização de Link</h3>
        <input
          type="url"
          name="url"
          value={linkForm.url}
          onChange={handleLinkChange}
          placeholder="URL"
          required
        />
        <button type="submit">
          {cadastradoLink ? "Atualizar Link" : "Cadastrar Link"}
        </button>
      </form>

      {message && <p className="message">{message}</p>}

      <div className="link-cadastrado">
        <h3>Link Cadastrado:</h3>
        {isLoading ? (
          <p>Carregando...</p>
        ) : cadastradoLink ? (
          <p>{cadastradoLink}</p>
        ) : (
          <p>Nenhum link cadastrado ainda.</p>
        )}
      </div>

      {cadastradosItems.length > 0 && (
        <div className="itens-cadastrados">
          <h3>Itens Cadastrados:</h3>
          <ul>
            {cadastradosItems.map((item, index) => (
              <li key={index}>
                {item.nome} - CPF: {item.cpf}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CadastroXLSX;
