import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import headline from "../assets/headline.png";
import illustration from "../assets/illustration.png";
import tracking from "../assets/rastreamento.png";
import code from "../assets/codigo.png";
import story from "../assets/historia.png";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { fetchItemByCpf } from "../services/services"; // Importando a função de serviço
import "./Consulta.css"; // Adicione estilos personalizados aqui

const Consulta = () => {
  const [cpf, setCpf] = useState("");
  const navigate = useNavigate();

  const handleSearch = async () => {
    try {
      const data = await fetchItemByCpf(cpf);

      // Navega para /tracking com os dados da consulta
      navigate("/tracking", { state: { data } });
    } catch (error) {
      toast.error(
        "Erro ao buscar dados da encomenda. Talvez você não tenha uma encomenda com a gente ou verifique o CPF e tente novamente.",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  return (
    <div className="consulta-container">
      {/* Cabeçalho com o título e subtítulo */}
      <header className="consulta-header">
        <img src={logo} alt="Logo" className="header-image" />
      </header>

      <section className="consulta-search-section">
        <div className="consulta-search-container">
          <img src={headline} alt="Logo" className="headline-image" />
          <div className="custom-headline-card">
            <img
              src={illustration}
              alt="Ilustração de rastreamento"
              className="illustration-image"
            />
            <p>
              Digite seu <strong>CPF</strong> no campo abaixo
            </p>
            <InputMask
              mask="999.999.999-99"
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
              placeholder="Digite seu CPF aqui"
              className="consulta-input"
            />
            <button className="consulta-button" onClick={handleSearch}>
              Buscar encomenda
            </button>
          </div>
        </div>
      </section>

      {/* Informações adicionais (continuação da segunda imagem) */}
      <section className="tracking-info-section">
        <div className="tracking-info-card">
          <img
            src={tracking}
            alt="Ilustração de rastreamento"
            className="card-image card image"
          />
          <div className="information-itens">
            <h3 className="information-title">Rastreamento</h3>
            <p className="information-text">
              Acompanhe em tempo real o status de entrega dos seus pacotes,
              saiba onde eles estão e esteja sempre um passo à frente.
            </p>
          </div>
        </div>
        <div className="tracking-info-card">
          <img
            src={code}
            alt="Ilustração de rastreamento"
            className="card-image"
          />
          <div className="information-itens">
            <h3 className="information-title">Como rastrear um objeto?</h3>
            <p className="information-text">
              Basta informar o mesmo CPF utilizado em sua compra.
              <br />
              Exemplo: 123.456.789-09
            </p>
          </div>
        </div>
      </section>

      <section className="main-content-section">
        <img
          src={story}
          alt="Ilustração de rastreamento"
          className="main-image"
        />
        <p className="main-text">
          Liderando com propósito de sustentabilidade Possuímos um forte
          compromisso de equilibrar os aspectos ambiental, social, econômico e a
          governança. Essa abordagem global não apenas impulsiona nossa empresa,
          mas também molda o futuro que estamos construindo. Conheça nossa
          Política de Sustentabilidade Empresarial.
        </p>
      </section>

      {/* Rodapé com informações adicionais */}
      <footer className="consulta-footer">
        <h3>Precisa de ajuda?</h3>
        <p>
          Precisa de ajuda? Entre em contato através do e-mail:
          atendimento@correios.com
        </p>
        <p>CNPJ 34.028.316/0001-03 | Empresa Brasileira de Correios e Telégrafos</p>
      </footer>
    </div>
  );
};

export default Consulta;
