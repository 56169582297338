import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Consulta from './pages/Consulta.jsx';
import Tracking from './pages/Tracking.jsx';
import { ToastContainer } from 'react-toastify';
import Login from './pages/Login.jsx';
import CadastroXLSX from './pages/CadastroXLSX.jsx';
import ProtectedRoute from './components/ProtectedRoute.jsx';
import 'react-toastify/dist/ReactToastify.css'; // Importa o CSS do react-toastify

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = (loginSuccess) => {
    setIsAuthenticated(loginSuccess);
  };
  return (
    <Router>
      <div>
        {/* Container para exibir as notificações */}
        <ToastContainer 
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route path="/consulta" element={<Consulta />} />
          <Route path="/tracking" element={<Tracking />} />
          <Route path="/" element={<Navigate to="/consulta" replace />} />
          <Route path="*" element={<Navigate to="/consulta" replace />} />
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
          <Route path="/CadastroXLSX" element={<CadastroXLSX />} />
        </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
