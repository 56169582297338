import axios from 'axios';

export const fetchItemByCpf = async (cpf) => {
  try {
    const response = await axios.get(`https://api.transportesseguros.com.br/api/items/${cpf}`, {
      headers: {
        "Accept": "application/json"
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar item:', error);
    if (error.response) {
      throw new Error(`Erro na requisição: ${error.response.status} - ${error.response.statusText}`);
    } else if (error.request) {
      throw new Error('Nenhuma resposta recebida do servidor.');
    } else {
      throw new Error('Erro ao fazer a requisição.');
    }
  }
};

export const fetchLink = async () => {
  try {
    const response = await axios.get(`https://api.transportesseguros.com.br/api/links`, {
      headers: {
        "Accept": "application/json"
      }
    });

    return response.data; // Retorna o link do servidor
  } catch (error) {
    console.error('Erro ao buscar link:', error);
    if (error.response) {
      throw new Error(`Erro na requisição: ${error.response.status} - ${error.response.statusText}`);
    } else if (error.request) {
      throw new Error('Nenhuma resposta recebida do servidor.');
    } else {
      throw new Error('Erro ao fazer a requisição.');
    }
  }
};
