import React, { useState } from "react";
import logo from "../assets/logo.png";
import olhoAberto from "../assets/olho-aberto.png";
import olhoFechado from "../assets/olho-fechado.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Login.css";

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      setError(null);
      const response = await axios.post(
        "http://localhost:5149/api/Auth/login",
        {
          username,
          password,
        }
      );

      if (response.data === true) {
        onLogin(true);
        navigate("/CadastroXLSX");
      } else {
        setError("Credenciais inválidas. Por favor, tente novamente.");
      }
    } catch (err) {
      setError("Falha no login. Verifique suas credenciais e tente novamente.");
    }
  };

  return (
    <div className="login-container">
      <header className="login-header">
        <img src={logo} alt="Logo" className="login-logo" />
      </header>
      <section className="login-section">
        <h2>Faça login na sua conta</h2>
        <div className="login-card">
          <input
            type="text"
            placeholder="Usuário"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="login-input"
          />
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="login-input"
            />
            <img
              src={showPassword ? olhoAberto : olhoFechado}
              alt={showPassword ? "Ocultar senha" : "Mostrar senha"}
              onClick={() => setShowPassword(!showPassword)}
              className="password-toggle"
            />
          </div>
          <button onClick={handleLogin} className="login-button">
            Entrar
          </button>
          {error && <p className="error-message">{error}</p>}
        </div>
      </section>
    </div>
  );
};

export default Login;
